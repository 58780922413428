<template>
  <div class="app-mall-order-detail container">
    <div class="content ex padding-20-0">
      <div class="h c">
        <el-button icon="el-icon-back" @click="handleBack"></el-button>
        <div class="flex fs-large padding-0-10">订单详情</div>
      </div>
      <el-form :model="form" label-width="62px" label-position="right" label-suffix=":" v-if="form">
        <div class="h s">
          <div style="width: 350px; margin-right: 50px;">
            <div class="item-title">基本信息</div>
            <div class="item-content">
              <form-info-item label="订单编号">{{form.formCode}}</form-info-item>
              <form-info-item label="ERP编号" v-if="form.erpCode">{{form.erpCode}}</form-info-item>
              <form-info-item label="采购时间">{{new Date(form.purchaseTime).format()}}</form-info-item>
              <form-info-item label="订单状态">
                <dot :type="status[form.status].type" same>{{status[form.status].label}}</dot>
              </form-info-item>
            </div>
          </div>
          <div class="flex">
            <div class="item-title">收货信息</div>
            <div class="item-content">
              <form-info-item label="收货人">{{form.receiverName}}（{{form.receiverPhone}}）</form-info-item>
              <form-info-item label="收货地址">{{form.recProvince}}{{form.recCity}}{{form.recDistrict}}{{form.recAddress}}</form-info-item>
            </div>
          </div>
        </div>

        <div class="item-title">商品清单</div>
        <div class="item-content">
          <el-table :data="form.items" empty-text="当前采购单没有任何商品">
            <el-table-column prop="code" label="商品编码" min-width="130" />
            <el-table-column prop="erpCode" label="ERP编码" min-width="130" />
            <el-table-column label="商品" min-width="240">
              <div class="h" style="line-height: 1.5" slot-scope="scope">
                <template v-if="scope.row.isGift">
                  <el-tag size="mini" type="danger">赠</el-tag>&emsp;
                </template>
                <div>
                  <div>{{scope.row.goodsName}}</div>
                  <div class="fc-g">{{$goodsSpecConvert(scope.row.goodsSpec)}}</div>
                  <template v-if="scope.row.promotionId">
                    <div class="fc-e" v-if="scope.row.promotionJoinType === 'groupSale'">组合销售：{{scope.row.groupSaleName}}</div>
                    <div class="fc-e" v-if="scope.row.promotionId">商品促销：{{scope.row.promotionName}}</div>
                    <div class="fc-e" v-if="scope.row.wholePromotionId">整单优惠：{{scope.row.wholePromotionName}}</div>
                  </template>
                </div>
              </div>
            </el-table-column>
            <el-table-column label="期待发货时间" width="100" :formatter="r => { return new Date(r.shipDate).format('yyyy/MM/dd'); }" />
            <el-table-column label="单价" width="130" align="right">
              <div style="line-height: 1.5" slot-scope="scope">
                <b>{{$price(scope.row.realPrice)}}</b>
                <div class="fc-g through" v-if="scope.row.price > scope.row.realPrice">{{$price(scope.row.price)}}</div>
              </div>
            </el-table-column>
            <el-table-column prop="count" label="数量" align="center" width="80" />
            <el-table-column prop="cancelCount" label="取消数量" align="center" width="80" />
          </el-table>
          <div class="padding-10 h c r">
            <template v-if="form.discountAmount > 0">
              <form-info-item label="商品金额" style="margin-right: 50px;">
                <b>{{$price(form.totalMoney)}}</b>
              </form-info-item>
              <form-info-item label="优惠金额" style="margin-right: 50px;">
                <b class="fc-e">{{$price(form.discountAmount)}}</b>
              </form-info-item>
            </template>
            <form-info-item label="返利抵扣" style="margin-right: 50px;" v-if="form.useRebate && form.rebateAmount > 0">
              <b class="fc-e">{{$price(form.rebateAmount)}}</b>
            </form-info-item>
            <form-info-item label="订单金额" content-style="padding-right: 10px;">
              <b>{{$price((form.realTotalMoney || 0) - (form.rebateAmount || 0))}}</b>
            </form-info-item>
          </div>
        </div>

        <div class="item-title">支付信息</div>
        <div class="item-content">
          <pays-info :order="form" mall v-if="payable" />
          <form-info-item label-position="right" label-suffix=":" label="支付方式" v-else>{{payTypes[form.payType]}}</form-info-item>
        </div>

        <div class="item-title">其他信息</div>
        <div class="item-content">
          <form-info-item label="备注">{{form.info}}</form-info-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { get } from "@/api/purchaseOrder";
import paysInfo from "@/views/purchase/order/pays-info";

export default {
  components: { paysInfo },
  data() {
    return {
      form: null,
      payTypes: ["线上支付", "线下支付"],
      status: [
        {
          type: "info",
          label: "编辑中"
        },
        {
          type: "warning",
          label: "待审批"
        },
        {
          type: "success",
          label: "已确认"
        },
        {
          type: "danger",
          label: "已拒绝"
        },
        {
          type: "warning",
          label: "待发货"
        },
        {
          type: "success",
          label: "部分已发货"
        },
        {
          type: "success",
          label: "已发货"
        },
        {
          type: "success",
          label: "部分已收货"
        },
        {
          type: "success",
          label: "已完成"
        },
        {
          type: "info",
          label: "已关闭"
        },
        {
          type: "info",
          label: "已取消"
        },
        {
          type: "info",
          label: "部分已关闭"
        }
      ]
    };
  },
  computed: {
    payable() {
      if (this.form) {
        if (this.form.payType === 1)
          return [0, 1, 3].indexOf(this.form.status) < 0;
        else return this.form.status !== 3;
      }
      return false;
    },
    totalMoney() {
      let c = 0;
      if (!this.form) return "-";
      (this.form.items || []).forEach(o => {
        // if (typeof o.count !== "number" || o.count < 1) o.count = 1;
        c += o.price * o.count;
      });
      return (c / 100).toFixed(2);
    }
  },
  methods: {
    handleBack() {
      window._mall_handleback = true;
      this.$router.back();
    },
    load() {
      get(this.$route.params.id).then(res => {
        this.form = res;
      });
    }
  },
  activated() {
    this.load();
  }
};
</script>